import React, { useState, useEffect } from "react";
import Layout from "../components/Layout/Layout";
import { CollectionGrid } from "components";
import { graphql } from "gatsby";

const Tiles = ({ data }) => {
	const [collections, setCollections] = useState([]);

	useEffect(() => {
		if (!data) return;
		const filter = data.allShopifyCollection.edges
			.filter(({ node }) => node.productsCount > 1 && node)
			.map(({ node }) => node);
		setCollections(filter);
	}, [data]);

	return (
		<Layout title="Tiles" collection={true} marginTop={30}>
			<CollectionGrid data={collections} />
		</Layout>
	);
};

export const query = graphql`
	{
		allShopifyCollection {
			edges {
				node {
					title
					handle
					id
					productsCount
					description
					image {
						gatsbyImageData
						altText
					}
					products {
						tags
					}
				}
			}
		}
	}
`;
export default Tiles;
